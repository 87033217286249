// HEADER
.brand {
	padding: rem-calc(0 0);
	position: relative;
	img {
		max-width: 340px;
		margin-top: rem-calc(-15);
		margin-bottom: rem-calc(-10);
	}

	&:before {
		content: '';
		width: 1000%;
		height: 100%;
		position: absolute;
		right:130%;
		// background: $success-color;
		display: block;
		top: 0;
	}

	@include breakpoint(large down) {
		img {
			max-width: 200px;
			padding-bottom: 8px;	
		}
	}
}

// MAIN NAVIGATION
.site-header {
	position: absolute;
	// top: 50px;
	z-index: 1006;
	width: 100%;

	> .top_bar {
		padding: rem-calc(8 50);
		.button {
			margin: 0;
		}
	}

	.top-bar {
		position: relative;

		.top-bar-right {
			position: absolute;
			right: 50px;
		}

		.top_bar {
			margin-top: rem-calc(10);
			display: inline-block;
		}

		#main-menu {
			margin: rem-calc(7 0);

			li {
				display: inline-block;
				a {
					font-weight: 700;

					&:not(.button, .link) {
						color: $dark-gray;

						&:hover {
							color: $success-color;
						}
					}

					&.link {
						color: $medium-gray;
						border-left: 1px solid $medium-gray;

						i {
							font-size: rem-calc(18);
						}

						&:hover {
							color: $success-color;
						}
					}

					&.button {
						padding: rem-calc(20 40);
					}
				}

				&.active {
					a {
						background: transparent;
						&:not(.button, .link) {
							color: $success-color;
						}
					}
				}
			}
		}

		.top-bar-right {
			// position: relative;
		}

		// SEARCH BAR
		#search-bar {
			position: absolute;
			top: 50%;
	    margin-top: -27px;
			z-index: 1;
			display: none;
			padding-left: rem-calc(10);
			padding-top: rem-calc(10);
			padding-bottom: rem-calc(10);
			
			form {
				background: $light-gray;

				.input-group {
					margin-bottom: 0;

					.input-group-field {
						max-width: 100%;
						border: 0;
						background: transparent;
						box-shadow:none;
						border-left: 1px solid $medium-gray;
						min-height: 35px;
						height: 35px;
						padding: rem-calc(0 30);
					}

					button {
						min-height: 35px;
						height: 35px;

						i {
							color: $medium-gray;
							font-size: rem-calc(18);
						}
					}
				}
			}

			@include breakpoint(medium down) {
				background: $light-gray;
				top: 0;
				margin-top: 0;
				height: 100%;
			}
		}

		@include breakpoint(1580 down) {
			#main-menu {
				margin: rem-calc(17 0);

				li {
					a {
						&.email {
							display: none;
						}
						&.button {
							padding: rem-calc(10 20);
						}
					}
				}
			}
		}

		@include breakpoint(large down) {
			#main-menu {
				margin: rem-calc(0 0);

				li {
					a {
						padding: rem-calc(10);
						font-size: rem-calc(14);
					}
				}
			}
		}

		@include breakpoint(medium down) {
			text-align: center;

			.top-bar-right {
				position: relative;
				right: 0;
			}
		}

		@include breakpoint(small down) {
			padding: rem-calc(0 10);
			text-align: left;

			#main-menu {
				li {
					display: block;

					a {
						&.link {
							display: block;
							border-left: 0;
						}
					}
				}
			}
		}
	}
}

// MOBILE MENU
.title-bar {
	display: none;
	.title-bar-title {
		a {
			display: block;

			img {
				max-width: 200px;
			}
		}
	}

	.menu-icon {
		float: right;
		width: 25px;
		height: 18px;
		margin: rem-calc(18 0);

		&:after {
			height: 3px;
			background: $dark-gray;
			box-shadow: 0 7px 0 $dark-gray, 0 14px 0 $dark-gray;
		}
	}
}

@include breakpoint(medium down) {
	.top-bar .top-bar-left,
	.top-bar .top-bar-right {
		width: 100%;
	}
}