// GENERAL
	// BUTTONS
	.button:not(.small) {
		font-family: $body-font-family;
		font-weight: 700;
		padding: rem-calc(20 30);
		font-size: rem-calc(14);

		&.hollow {
			&.white {
				border: 1px solid $white;
    		color: $white;
			}
		}
	}

	// FORMS
	[type='text'],
	[type='password'],
	[type='date'],
	[type='datetime'],
	[type='datetime-local'],
	[type='month'],
	[type='week'],
	[type='email'],
	[type='number'],
	[type='search'],
	[type='tel'],
	[type='time'],
	[type='url'],
	[type='color'],
	textarea {
		min-height: 60px;
		padding: rem-calc(15 20);
	}

	textarea {
		min-height: 150px;
	}

	// CALLOUT
	.callout {
		border: 0;
		padding: rem-calc(40);
		margin-bottom: rem-calc(30);

		h3 {
			color: $blue;
			font-style: italic;
			font-weight: 400;
			margin-bottom: rem-calc(20);
		}

		@include breakpoint(small down) {
			padding: rem-calc(20);
		}
	}

	// SEARCH OVERLAY
	#search-overlay {
		overflow-y: initial;
	}

// HELPER CLASSES
.alert-color {
	color: $alert-color;
}

.white-color {
	color: $white;
}

.blue-color {
	color: $blue;
}

.dark-gray-color {
	color: $dark-gray;
}

.green-color {
	color: $success-color;
}

.link {
	font-weight: 700;

	&:hover {
		color: scale-color($success-color, $lightness: -15%) !important;
	}
}

.bg_white {
	background: $white;
}

.bg_gray {
	background: $light-gray;
}

.bg_green {
	background: $success-color;
}

.pt0 {
	padding-top: 0 !important;
}

.regular {
	font-weight: 400;
	color: #7c7c7c;
}

// HERO
#hero {
	min-height: 500px;
	position: relative;
	background-position: center center;
	background-size: cover;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-ms-background-size: cover;
	-o-background-size: cover;
	padding-top: rem-calc(270);

	&.home {
		min-height: 760px;
	}

	.title {
		color: $white;

		h1 {
			font-size: rem-calc(60);
			line-height: 1.2;
			margin-bottom: rem-calc(30);
			color: $white;
    	text-shadow: 0px 8px 12px rgba(0,0,0, .2);
		}
	}

	@include breakpoint(large down) {
		min-height: 500px;

		&.home {
			min-height: 600px;
		}

		.title {
			h1 {
				font-size: rem-calc(40);
			}
		}
	}

	@include breakpoint(small down) {
		min-height: 320px;
		padding-top: rem-calc(170);

		&.home {
			min-height: 450px;
		}

		.title {
			h1 {
				font-size: rem-calc(30);
			}
		}
	}
}

// GOOGLEMAP
#googlemap {
	position: relative;

	&:after {
		content: '';
		background: rgba(0,0,0, .3);
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
	}

	#google_map_container {
		min-height: 500px;
	}

	.row {
		position: absolute;
		bottom: 120px;
		left: 0;
		right: 0;
		z-index: 1;
	}

	.title {
		color: $white;

		h1 {
			font-size: rem-calc(60);
			line-height: 1.2;
			margin-bottom: rem-calc(30);
			color: $white;
    	text-shadow: 0px 8px 12px rgba(0,0,0, .2);
		}
	}
}

// MAIN CONTENT
.main-content {
	padding: rem-calc(90 0);

	h2 {
		color: $dark-gray;
		margin-bottom: rem-calc(30);
		// font-size: rem-calc(60);

		// @include breakpoint(medium down) {
		// 	font-size: rem-calc(50);
		// }

		// @include breakpoint(small down) {
		// 	font-size: rem-calc(36);
		// }
	}

	h3,h4 {
		margin-bottom: rem-calc(20);	
	}


	@include breakpoint(small down) {
		padding: rem-calc(45 0);		
	}
}

// LOCATIONS
#locations {
	a:not(.button) {
		display: block;
	}
	.button {
		margin-top: rem-calc(20);
	}
}

// LOGOS
#logos {
	padding: rem-calc(60 0 0);

	.column {
		margin-bottom: rem-calc(50);
	}
	@include breakpoint(medium up) {
		a {
			display: table-cell;
		    vertical-align: middle;
		    width: 300px;	
		    max-width: 300px;

			img {
				display: inline-block;
			}
		}
	}
}