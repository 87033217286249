// HOME SLIDER
// #home_slider {
// 	position: relative;
// 	overflow: hidden;

// 	.slide {
// 		min-height: 740px;
// 		background-position: center center;
// 		background-size: cover;
// 		position: relative;
// 		z-index: 1;

// 		.caption {
// 			display: none;
// 		}
// 	}

// 	#global_caption {
// 		background: $blue;
// 		color: $white;
// 		position: absolute;
// 		right: 50px;
// 		bottom: 28%;
// 		z-index: 1;
// 		padding: rem-calc(20 30 20);
// 		font-family: $font-baskerville;

// 		h2 {
// 			font-size: rem-calc(48);
// 			margin-bottom: 0;
// 		}

// 		p {
// 			font-size: rem-calc(30);

// 			&:before {
// 				content: '';
// 				display: inline-block;
// 				width: 100px;
// 				height: 1px;
// 				background: $white;
// 				vertical-align: middle;
// 				margin-right: rem-calc(15);
// 			}
// 		}

// 		.button {
// 			position: absolute;
// 			right: 30px;
// 			text-transform: uppercase;
// 			margin: 0;
// 			bottom: -25px;
// 		}
// 	}

// 	.custom_paging {
// 		position: absolute;
// 		top: 0;
// 		right: 0;
// 		height: 100%;
// 		background: $light-gray;
// 		width: 120px;
// 		text-align: center;
// 		z-index: 0;
// 		overflow: hidden;
// 		padding-top: 5px;

// 		li {
// 			margin: rem-calc(44 0);
// 			-webkit-transform: rotate(-90deg);
// 			-moz-transform: rotate(-90deg);
// 			-ms-transform: rotate(-90deg);
// 			-o-transform: rotate(-90deg);
// 			filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
// 			color: $medium-gray;
// 			font-weight: bold;
// 			text-align: right;

// 			&:last-child {
// 				&:before {
// 					display: none;
// 				}
// 			}

// 			&:before {
// 				content: '';
// 				display: inline-block;
// 				width: 40px;
// 				height: 1px;
// 				background: $medium-gray;
// 				vertical-align: middle;
// 				margin-right: 5px
// 			}

// 			&.slick-active {
// 				color: $alert-color;
// 			}
// 		}
// 	}

// 	@include breakpoint(large down) {
// 		.slide {
// 			min-height: 540px;
// 		}

// 		#global_caption {
// 			h2 {
// 				font-size: rem-calc(35);
// 			}
// 			p {
// 				font-size: rem-calc(22);
// 			}
// 		}

// 		.custom_paging {
// 			width: 90px;
// 		}
// 	}

// 	@include breakpoint(medium down) {
// 		.slide {
// 			min-height: 440px;
// 		}
// 		#global_caption {
// 			position: relative;
// 			bottom: 0;
// 			right: 0;

// 			h2 {
// 				font-size: rem-calc(30);
// 			}
// 			p {
// 				font-size: rem-calc(18);

// 				&:before {
// 					width: 50px;
// 				}
// 			}

// 			.button {
// 				position: relative;
// 				bottom: 0;
// 				margin:0 auto;
// 				max-width: 170px;
// 				width: 100%;
// 				right: 0;
// 			}
// 		}
// 	}

// 	@include breakpoint(small down) {
// 		.slide {
// 			min-height: 340px;
// 		}

// 		.custom_paging {
// 			width: 70px;
// 		}
// 	}
// }

// CTA
#home_cta {
	padding: rem-calc(105 0 75);
	background: $light-gray;

	h2 {
		font-size: rem-calc(60);
		color: $black;
		margin-bottom: rem-calc(30);
	}

	.columns {
		position: relative;
		padding: rem-calc(50);

		&:first-child {
			padding: rem-calc(0 15);
		}

		&:last-child {
			margin-top: -60px;
		}
	}

	.callout {
		position: static;
		-webkit-box-shadow: 0px 17px 50px 0px rgba(0,0,0,0.2);
		-moz-box-shadow: 0px 17px 50px 0px rgba(0,0,0,0.2);
		box-shadow: 0px 17px 50px 0px rgba(0,0,0,0.2);

		img {
			position: absolute;
			top: 0;
			left: 0;
		}

		.description {
			position: relative;

			h3 {
				font-weight: 700;
				font-style: normal;
				color: $success-color;
				padding-left: 100px;
			}

			a {
				font-weight: 700;
			}

			.button {
				position: absolute;
				top: 100%;
				margin: 25px 0 0;

			}
		}
	}

	@include breakpoint(medium down) {
		padding: rem-calc(55 0 35);

		h2 {
			font-size: rem-calc(40);
		}

		.columns {
			padding: rem-calc(50 30);
		}

		.callout {
			img {
				max-width: 100px;
			}

			.description {
				h3 {
					padding-left: 0px;	
				}
			}
		}
	}

	@include breakpoint(small down) {
		h2 {
			font-size: rem-calc(30);
		}
		.columns {
			padding: rem-calc(25);
			&:last-child {
				margin-top: 0px;
			}
		}

		.callout {
			img {
				max-width: 100px;
			}

			.description {
				margin-top: rem-calc(50);

				.button {
					margin: 10px 0 0;
				}
			}
		}
	}
}

// ABOUT
#home_about {
	position: relative;
	
	ul {
		margin: 0;

		li {
			.image {
				position: absolute;
				right: 0;
				padding: 0;

				figure {
					background-position: left center;
					background-size: cover;
					-webkit-background-size: cover;
					-moz-background-size: cover;
					-ms-background-size: cover;
					-o-background-size: cover;
				}
			}
			
			.content {
				padding: rem-calc(126 45);

				h2 {
					color: $black;
					margin-bottom: rem-calc(30);
				}
			}

			&:nth-child(2n+1) {
				.image {
					left: 0;
				}
			}
		}
	}
	
	@include breakpoint(medium down) {
		ul {
			li {
				.content {
					padding: rem-calc(60 0);

					h2 {
						font-size: rem-calc(25);
					}
				}
			}
		}
	}

	@include breakpoint(small down) {
		ul {
			li {
				.image {
					position: relative;

					figure {
						min-height: 300px;
					}
				}
			}
		}
	}
}