.our-staff {
	h2 {
		color: $dark-gray;
	}

	.staff-list {
		margin-top: rem-calc(30);

		li {
			margin: 0 0 rem-calc(30);

			figure {
				background: $white;
				padding-left: 0;
			}

			.content {
				background: $white;
				padding-top: rem-calc(30);
				padding-right: 30px;

				h3 {
					color: $blue;
					font-style: italic;
					font-weight: 400;
					margin-bottom: rem-calc(20);
				}
			}
		}
	}

	.staff {
		

	}
}