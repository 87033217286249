footer {
	background: $dark-gray;
	padding: rem-calc(70 0);

	.bottom {
		margin-top: rem-calc(30);
	}

	.menu {
		li {
			display: inline-block;

			&:first-child {
				a {
					padding-left: 0;
				}
			}

			a {
				color: #e6e6e6;
				font-weight: 400;

				&:hover {
					color: $success-color;
				}
			}

			&.active {
				a {
					background: transparent;
					color: $success-color;
				}
			}
		}
	}

	.social {
		li {
			display: inline-block;
			margin: rem-calc(0 10);

			&:last-child {
				margin-right: 0;
			}
			a {
				color: #e6e6e6;
				font-size: rem-calc(25);

				&:hover {
					color: $success-color;
				}
			}
		}

		@include breakpoint(medium only) {
			li {
				margin: rem-calc(0 5);

				a {
					font-size: rem-calc(20);
				}
			}
		}
	}
}